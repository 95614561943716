<template>
  <ul class="flex items-center space-x-4">
    <li class="sm:flex sm:items-center">
      <base-dropdown
        ref="filterDropdown"
        direction="left"
        class="relative"
        @closed="activeFilterBox = null"
      >
        <base-button slot="trigger" ref="myBtn" :disabled="loading">
          Filter
          <icon-plus class="w-4 h-4"></icon-plus>
        </base-button>

        <div slot="menu" class="p-4 w-80" @click.stop>
          <focus-trap :value="activeFilterBox == null">
            <div>
              <div
                v-if="activeFilterBox"
                class="bg-white shadow-md absolute left-72 rounded-md top-0"
              >
                <focus-trap :value="true">
                  <filter-form
                    :column="activeFilterBox"
                    :selected-filter="selectedFilter"
                    @filter="onFilterApplied"
                    @close="$refs.filterDropdown.close()"
                  ></filter-form>
                </focus-trap>
              </div>

              <h3 class="text-gray-700 text-sm font-medium">Ofte anvendt</h3>
              <ul class="grid grid-cols-2 mt-1">
                <filter-item
                  v-for="(column, index) in commonFilters"
                  :key="column.field"
                  :column="column"
                  :tab-index="index + 1"
                  :is-active="activeFilterBox == column"
                  @click.native="activeFilterBox=column"
                ></filter-item>
              </ul>

              <button
                class="flex justify-between w-full mt-4 group hover:text-gray-500"
                @click.stop="showAdvancedFilters = !showAdvancedFilters"
              >
                <h3 class="text-gray-700 text-sm font-medium group-hover:text-gray-500">Flere</h3>

                <icon-plus v-if="!showAdvancedFilters" class="h-4 w-4"></icon-plus>
                <icon-minus v-else class="h-4 w-4"></icon-minus>
              </button>
              <ul v-if="showAdvancedFilters" class="grid grid-cols-2">
                <filter-item
                  v-for="(column, index) in nonCommonFilters"
                  :key="column.field"
                  :column="column"
                  :tab-index="commonFilters.length + index + 1"
                  :is-active="activeFilterBox == column"
                  @click.native="activeFilterBox=column"
                ></filter-item>
              </ul>
            </div>
          </focus-trap>
        </div>
      </base-dropdown>
    </li>

    <filter-active-pill
      v-for="(filter, index) in model"
      :key="index"
      :filter="filter"
      @open="openFilter(index)"
      @remove="removeFilter(index)"
    ></filter-active-pill>

    <li v-if="model.length">
      <filter-clear-button @click.native="clearFilters"></filter-clear-button>
    </li>
  </ul>
</template>

<script>
import FilterForm from '@/components/table/FilterForm.vue';
import FilterItem from '@/components/table/FilterItem.vue';
import FilterActivePill from '@/components/table/FilterActivePill.vue';
import FilterClearButton from '@/components/table/FilterClearButton.vue';
import { FocusTrap } from 'focus-trap-vue';

export default {
  name: 'FilterMenu',
  components: {
    FilterForm,
    FilterItem,
    FilterActivePill,
    FilterClearButton,
    FocusTrap,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
      required: true,
      description: 'List of active filters',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAdvancedFilters: false,
      activeFilterBox: '',
      selectedFilter: null,
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.$emit('update');
      },
    },
    commonFilters() {
      return this.config.columns.filter((x) => x.filterable && x.commonFilter);
    },
    nonCommonFilters() {
      return this.config.columns.filter((x) => x.filterable && !x.commonFilter);
    },
  },
  methods: {
    async removeFilter(index) {
      this.model = [...this.value.filter((_v, i) => i !== index)];
      this.activeFilterBox = '';
      this.selectedFilter = null;
    },
    async openFilter(index) {
      const filter = this.value[index];
      this.selectedFilter = filter;
      this.activeFilterBox = filter.column;
      this.$refs.filterDropdown.forceOpen();
    },
    async onFilterApplied({ filterType, value, column }) {
      // prevent the user from having 2 identical filters
      if (this.value.some((existingFilter) => existingFilter.type === filterType && existingFilter.value === value && existingFilter.column.field === column.field)) {
        this.$refs.filterDropdown.close();
        return;
      }

      this.model = [
        ...this.value,
        {
          value,
          type: filterType,
          column,
        },
      ];
      this.$refs.filterDropdown.close();
    },
    clearFilters() {
      this.model = [];
    },
  },
};
</script>

<style>

</style>
