import ApiService from '@/common/api.service';

const MaterielService = {
  getAll() {
    const resource = 'materiels/';
    return ApiService.get(resource);
  },

  update(payload) {
    const resource = 'materiels';
    return ApiService.put(resource, payload.id, payload);
  },
};

export default MaterielService;
