<template>
  <base-modal
    v-model="model"
    :width="512"
    title="Opdatering"
    :can-overflow="true"
    :close-on-overlay-click="true"
  >
    <div class="flex flex-col pr-4 py-2">
      <p class="font-semibold text-gray-700 mt-2">Du er ved at opdatere {{count}} opgaver</p>

      <base-alert
        v-if="isUpdatingAll"
        title="Mulig farlig handling"
        description="Du er ved at opdatere samtlige opgaver i workspacet."
        type="warning"
        class="mt-4"
      ></base-alert>

      <div
        v-if="!isLightWorkspace"
        class="border-gray-300 bg-gray-50 rounded-md mt-4 py-2 px-4 flex justify-between items-center cursor-pointer"
        @click.stop="reset(!isMultiDisposalDay)"
      >
        <label class="text-gray-700 cursor-pointer">Flere end én tømningsdage om ugen</label>

        <base-input type="text">
          <base-switch
            slot="input"
            :value="isMultiDisposalDay"
            @change="reset(!isMultiDisposalDay)"
          ></base-switch>
        </base-input>
      </div>

      <tooltip
        content="Hvis du skal opdatere opgaver med flere tømningsdage om ugen, så skal du vælge denne til"
      >
        <icon-question-mark-circle class="text-gray-600 h-5 w-5 mt-2 ml-auto"></icon-question-mark-circle>
      </tooltip>

      <div class="grid grid-cols-1 gap-4">
        <base-input v-if="isMultiDisposalDay && !isLightWorkspace" label="Tømningsdage">
          <base-select-multi
            slot="input"
            v-model="multi_planned_disposal_day"
            placeholder="Vælg.."
            :options="plannedDisposalDayOptions"
            tabindex="1"
            width="w-full"
            :value-label-transformer="valueLabelTransformer"
          ></base-select-multi>
        </base-input>

        <base-input v-if="!isMultiDisposalDay && !isLightWorkspace" label="Tømningsdag">
          <base-select
            slot="input"
            v-model="planned_disposal_day"
            placeholder="Vælg.."
            :options="plannedDisposalDayOptions"
            width="w-full"
            tabindex="1"
            :clearable="true"
          ></base-select>
        </base-input>

        <base-input label="Køretøj">
          <base-select
            slot="input"
            v-model="vehicle"
            :clearable="true"
            placeholder="Vælg.."
            width="w-full"
            tabindex="2"
            :options="vehicleOptions"
          ></base-select>
        </base-input>

        <base-input v-if="!isLightWorkspace" label="Uge">
          <base-select
            slot="input"
            v-model="planned_week_no"
            :clearable="true"
            :options="plannedWeekNoOptions"
            width="w-full"
            tabindex="3"
            placeholder="Vælg.."
          ></base-select>
        </base-input>

        <base-input v-if="!isLightWorkspace" label="Ejendomsstatus">
          <base-select
            slot="input"
            v-model="property_zone"
            :clearable="true"
            placeholder="Vælg.."
            :options="propertyZoneOptions"
            width="w-full"
            tabindex="4"
          ></base-select>
        </base-input>

        <div class="flex items-center justify-end space-x-4 mt-4">
          <base-flat-button
            color="gray"
            size="xs"
            text="Luk"
            :disabled="loading"
            @click="model = false"
          ></base-flat-button>
          <base-button
            v-loading="loading"
            color="indigo"
            class="w-32"
            :disabled="!anyValues"
            @click="submitBulkEdit"
          >Gem</base-button>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import daLocale from '@/locales/da-DK';

export default {
  name: 'BulkEditForm',
  props: {
    value: {
      type: Boolean,
    },
    count: {
      type: Number,
      default: 0,
    },
    isUpdatingAll: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    vehicleOptions: {
      type: Array,
      description: 'Array of vehicle pairs with a value/label key',
      default: () => [],
    },
    plannedDisposalDayOptions: {
      type: Array,
      description: 'Array of disposal day pairs with a value/label key',
      default: () => [],
    },
    propertyZoneOptions: {
      type: Array,
      description: 'Array of property-zone pairs with a value/label key',
      default: () => [],
    },
    plannedWeekNoOptions: {
      type: Array,
      description: 'Array of planned week no pairs with a value/label key',
      default: () => [],
    },
  },
  data() {
    return {
      property_zone: '',
      planned_week_no: '',
      planned_disposal_day: '',
      multi_planned_disposal_day: [],
      vehicle: '',
      isMultiDisposalDay: false,
    };
  },
  computed: {
    ...mapGetters('departments', ['isLightWorkspace']),
    model: {
      get() {
        return this.value;
      },
      set(show) {
        this.$emit('input', show);

        if (!show) {
          this.reset(false);
        }
      },
    },
    anyValues() {
      return this.property_zone || this.planned_week_no || this.vehicle || this.planned_disposal_day || this.multi_planned_disposal_day.length;
    },
  },
  methods: {
    valueLabelTransformer(values) {
      return daLocale.disposalDayList(values);
    },
    reset(isMultiDisposalDay) {
      this.property_zone = '';
      this.planned_week_no = '';
      this.planned_disposal_day = '';
      this.multi_planned_disposal_day = [];
      this.vehicle = '';

      this.isMultiDisposalDay = isMultiDisposalDay;
    },
    async submitBulkEdit() {
      const payload = {
        property_zone: this.property_zone,
        planned_week_no: this.planned_week_no,
        vehicle: this.vehicle,
        planned_disposal_day: this.planned_disposal_day,
        multi_planned_disposal_day: this.multi_planned_disposal_day,
      };
      this.$emit('submit', { payload, isMultiDisposalDay: this.isMultiDisposalDay });
      this.reset(false);
    },
  },
};
</script>
